import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AuthContextProvider } from './Contexts/AuthContext';
import { SnackBarProvider } from './Contexts/SnackbarContext';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// Fetch the Google OAuth Client ID from environment variables
const googleAuthClientKey = process.env.REACT_APP_GOOGLE_OAUTH!;

root.render(
  <React.StrictMode>
    {/* Wrap the entire app with BrowserRouter, GoogleOAuthProvider, and Context Providers */}
    <BrowserRouter>
      <GoogleOAuthProvider clientId={googleAuthClientKey}>
        <AuthContextProvider>
          <SnackBarProvider>
            <App />
          </SnackBarProvider>
        </AuthContextProvider>
      </GoogleOAuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
