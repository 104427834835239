import Logo from './Images/logo.svg';
import FacbookLogo from './Images/facebook.svg';
import GoogleLogo from './Images/google.svg';
import FooterLogo from './Images/footer-logo.svg';
import Loaction from './Images/location.svg';
import FooterFb from './Images/footerfb.svg';
import FooterInsta from './Images/footerinsta.svg';
import Carafter from './Images/carafter.png';
import CarBefore from './Images/carBefore.png';
import Upload from './Images/upload.svg';
import Error from './Images/error.svg';
import CarEhance from './Images/car-enhance.png';
import ErrorImage from './Images/erroeImage.png';
import RightImage from './Images/rightImage.png';
import Cemara from './Images/camera.svg';
import PhoneHand from './Images/phone-in-hand.svg';
import Car from './Images/car-image.svg';
import Editcar from './Images/edit-car.png';
import Share from './Images/Share.svg';
import Calender from './Images/Calender.svg';
import GreenTick from './Images/green-tick.svg';
import CarBorder from './Images/car-border.png';
import ReadyCar from './Images/ready-car.png';
import PhotoGraphy from './Images/photography-tips.png';
import TopView from './Images/top-view-car.png';
import ErrorIcon from './Images/erroeIcon.svg';
import ListIcon from './Images/list-icon.svg';
import PartnerShip from './Images/partnership.svg';
import Visa from './Images/visa.svg';
import MasterCard from './Images/master-card.svg';
import AmericanExpress from './Images/american-express.svg';
import Discover from './Images/discover.svg';
import Venom from './Images/venom.svg';
import Paypal from './Images/paypal.svg';
import userProfile from './Images/user-profile.png';
import Star from './Images/star.svg';
import AddIcon from './Images/add-icon.svg';
import newEditCar from './Images/NewEditedcar.png';
import newCar from './Images/Newcar.png';
import plate from './Images/plate.svg';
import hide from './Images/hide.svg';
import eye from './Images/eye.png';
import Sparkle from './Images/sparkle.png';
import SparklrCar from './Images/Sparkle-car.svg';
import SliderImage from './Images/slider-image.png';
import CarEnhanceTwo from './Images/car-enhance-2.png';
import CarEnhanceThree from './Images/car-enhance-3.png';
import PhotographyTip from './Images/tip.png';
import image88 from './Images/image 88.png';
import image103 from './Images/image 103.png';
import image104 from './Images/image 104.png';
import image107 from './Images/image 107.png';
import image108 from './Images/image 108.png';
import ForgotPassword from './Images/forgot-password.png';
import Key from './Images/Key.png';
import ArrowRight from './Images/Arrow-right.svg';
import MobileViewImage from './Images/mobileview-image.png'

export default {
  hide,
  eye,
  newEditCar,
  plate,
  newCar,
  Logo,
  FacbookLogo,
  GoogleLogo,
  FooterLogo,
  Loaction,
  FooterFb,
  FooterInsta,
  CarBefore,
  Carafter,
  Upload,
  Error,
  CarEhance,
  ErrorImage,
  RightImage,
  Cemara,
  PhoneHand,
  Car,
  Editcar,
  Share,
  Calender,
  GreenTick,
  CarBorder,
  ReadyCar,
  PhotoGraphy,
  TopView,
  ErrorIcon,
  ListIcon,
  PartnerShip,
  Visa,
  MasterCard,
  AmericanExpress,
  Discover,
  Venom,
  Paypal,
  userProfile,
  Star,
  AddIcon,
  Sparkle,
  SparklrCar,
  SliderImage,
  CarEnhanceTwo,
  CarEnhanceThree,
  PhotographyTip,
  image88,
  image103,
  image104,
  image107,
  image108,
  ForgotPassword,
  Key,
  ArrowRight,
  MobileViewImage
};
