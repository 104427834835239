import { FC, lazy, LazyExoticComponent, ReactElement } from 'react';

export type AppRoute = {
  key: string;
  path: string;
  isPrivate?: boolean;
  skipIfAuthenticated?: boolean;
  LazyComponent?: LazyExoticComponent<FC>;
  isLazyRoute?: boolean;
  Component?: ReactElement;
};

const LoginPage = lazy(() => import('./Pages/Auth/Login/Login'));
const RegisterPage = lazy(() => import('./Pages/Auth/Register/Register'));
const ForgotPassword = lazy(() => import('./Pages/Auth/Forgot/Forget-password'));
const Home = lazy(() => import('./Pages/Home/Home'));
const KarStudioGuide = lazy(() => import('./Pages/Guide/KarStudioGuide'));
const Plans = lazy(() => import('./Pages/Plans/Plans'));
const MyProject = lazy(() => import('./Pages/MyProjects/MyProjects'));
const CreateProject = lazy(() => import('./Pages/CreateProject/CreateProject'));
const NoPageFound = lazy(() => import('./Shared/Components/404Page'));

export const routes: AppRoute[] = [
  {
    key: 'login',
    path: '/login',
    skipIfAuthenticated: true,
    LazyComponent: LoginPage,
    isLazyRoute: true,
  },
  {
    key: 'register',
    path: '/register',
    skipIfAuthenticated: true,
    LazyComponent: RegisterPage,
    isLazyRoute: true,
  },
  {
    key: 'forgotpassword',
    path: '/forgotpassword',
    isPrivate: true,
    LazyComponent: ForgotPassword,
    isLazyRoute: true,
  },
  {
    key: 'guide',
    path: '/guide',
    isPrivate: true,
    LazyComponent: MyProject,
    isLazyRoute: true,
  },
  {
    key: 'editor',
    path: '/editor',
    isPrivate: true,
    LazyComponent: MyProject,
    isLazyRoute: true,
  },
  {
    key: 'editor',
    path: '/editor/new',
    isPrivate: true,
    LazyComponent: CreateProject,
    isLazyRoute: true,
  },
  {
    key: 'home',
    path: '/',
    isPrivate: false,
    LazyComponent: Home,
    isLazyRoute: true,
  },
  {
    key: 'plans',
    path: '/plans',
    isPrivate: true,
    LazyComponent: Plans,
    isLazyRoute: true,
  },
  {
    key: '404',
    path: '*',
    isPrivate: false,
    isLazyRoute: false,
    LazyComponent: NoPageFound,
  },
];
