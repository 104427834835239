import { useState } from 'react';
import Images from '../../../Images/Images';
import { useAuthentication } from '../../../Contexts/AuthContext';
import ConfirmationBox from '../../../Shared/Components/DialogBox/ConfirmDialogBox';
import { useLocation, useNavigate } from 'react-router-dom';

const Navbar = () => {
  const { isAuthenticated, authUser } = useAuthentication();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const isEditorPage = location?.pathname === '/editor';
  const isEditorList = location?.pathname === '/editor/new';

  const redirectToLogin = () => navigate('/login');

  const redirectToRegister = () => navigate('/register');

  const handleLogout = () => {
    setOpen(true);
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img src={Images.Logo} alt="logo" className="img-fluid logo" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          {!isEditorList && !isEditorPage ? (
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    aria-current="page"
                    href="/guide"
                  >
                    KarStudio Guide
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/editor">
                    Editor
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/plans">
                    Try KarStudio
                  </a>
                </li>
                <button
                  className="main-btn-filled nav-item"
                  onClick={() => navigate('/')}
                >
                  Back to KarKiosk
                </button>

                {!isAuthenticated && (
                  <>
                    <button className="main-btn" onClick={redirectToLogin}>
                      Login
                    </button>
                    <button
                      className="main-btn active"
                      onClick={redirectToRegister}
                    >
                      Register
                    </button>
                  </>
                )}

                {isAuthenticated && (
                  <div className="account-section">
                    <div className="account-text">
                      <p className="greeting">Hello, {authUser!.first_name}</p>
                      <p className="account" onClick={handleLogout}>
                        Logout
                      </p>
                      <ConfirmationBox open={open} setOpen={setOpen} />
                    </div>
                  </div>
                )}
              </ul>
            </div>
          ) : (
            <>
              <div>
                <button className="main-btn me-3">Available Credits</button>

                <button className="main-btn me-3">Add Credits+</button>
              </div>
              {isAuthenticated && (
                <div className="account-section">
                  <div className="account-text">
                    <p className="greeting">Hello, {authUser!.first_name}</p>
                    <p className="account" onClick={handleLogout}>
                      Logout
                    </p>
                    <ConfirmationBox open={open} setOpen={setOpen} />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </nav>
    </>
  );
};
export default Navbar;
