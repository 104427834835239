import { FC } from 'react';
import Images from '../../../Images/Images';
const Footer: FC = () => {
  return (
    <>
      <footer className="footer-main">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <img
                src={Images.FooterLogo}
                alt="footer-logo"
                className="img-fluid logo-footer"
              />
              <p className="para-text">
                Kar Studio is the best place to find your favorite car, truck or
                SUV and get insurance quotes at the same time. We have a wide
                selection of vehicles to choose from and we our technology can
                provide you with insurance recommendations before your make the
                purchase.
              </p>
            </div>
            <div className="col-lg-3 offset-1">
              <p className="socialLinks">Quick Links</p>
              <ul>
                <li>
                  <a href="" className="linkList">
                    Car Finder
                  </a>
                </li>
                <li>
                  <a href="" className="linkList">
                    Sell Privately
                  </a>
                </li>
                <li>
                  <a href="" className="linkList">
                    Car Insurance
                  </a>
                </li>
                <li>
                  <a href="" className="linkList">
                    Sell Your Car
                  </a>
                </li>
                <li>
                  <a href="" className="linkList">
                    Sitemap
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-3">
              <p className="socialLinks">Help Center</p>
              <ul>
                <li>
                  <a href="" className="linkList">
                    Car Finder
                  </a>
                </li>
                <li>
                  <a href="" className="linkList">
                    Sell Privately
                  </a>
                </li>
                <li>
                  <a href="" className="linkList">
                    Car Insurance
                  </a>
                </li>
                <li>
                  <a href="" className="linkList">
                    Sell Your Car
                  </a>
                </li>
                <li>
                  <a href="" className="linkList">
                    Sitemap
                  </a>
                </li>
              </ul>
            </div>
            {/* <div className="col-lg-3">
              <div className="loaction-main">
                <figure className="iconBg">
                  <img
                    src={Images.Loaction}
                    alt="location"
                    className="img-fluid location"
                  />
                </figure>
                <div className="location-area">
                  <p>OFFICE</p>
                  <p>8 The Grn Ste A Dover, DE 19901-3618</p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="copyright">
          <div className="container">
            <div className="copyright-box">
              <p>@ 2024 Kar Studio All rights reserved.</p>
              <figure>
                <img
                  src={Images.FooterInsta}
                  alt="footerfb"
                  className="img-fluid FooterSocial"
                />
                <img
                  src={Images.FooterFb}
                  alt="footerfb"
                  className="img-fluid FooterSocial"
                />
              </figure>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer;
